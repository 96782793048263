import React from 'react'
import { Outlet, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { isMobile } from '../../lib/util'
import './index.css'

const Home = () => {
  let inputRef = React.useRef(null)
  const location = useLocation()
  const navigate = useNavigate()

  const handleClose = () => {
    inputRef.current.checked = !inputRef.current.checked
  }
  const handleScroll = () => {
    if (!location.pathname.includes('main')) {
      navigate('/main')
    }
    const element = document.getElementById('contacts')
    element.scrollIntoView({block: "center", behavior: "smooth"});
  }

  return (
    <div>
      <div className="header">
        <div className="container d-flex header-container justify-between align-center">
          <div className="d-flex align-center">
            {isMobile() &&
              <nav>
                <div className="navbar">
                  <div className="nav-container">
                    <input ref={inputRef} className="checkbox" type="checkbox" name="" id="" />
                    <div className="hamburger-lines">
                      <span className="line line1"></span>
                      <span className="line line2"></span>
                      <span className="line line3"></span>
                    </div>  
                    <div className="menu-items" onClick={handleClose}>
                      <li><NavLink to="/main">
                        Главная
                      </NavLink></li>
                      <li><NavLink to="/catalog">
                        Каталог
                      </NavLink></li>
                      <li><NavLink to="/delivery">
                        Доставка и оплата
                      </NavLink></li>
                      <li onClick={handleScroll}><a>
                        Контакты
                      </a></li>
                    </div>
                  </div>
                </div>
              </nav>
            }
            <a className="header-logo" href="/">
              Химреактив-Снаб
            </a>
          </div>
          {!isMobile() &&
            <>
              <NavLink to="/main" className="header-link">
                Главная
              </NavLink>
              <NavLink to="/catalog" className="header-link">
                Каталог
              </NavLink>
              <NavLink to="/delivery" className="header-link">
                Доставка и оплата
              </NavLink>
              <div className="header-link" onClick={handleScroll}>
                Контакты
              </div>
            </>
          }
          <div className="header-contacts">
            <a className="header-contacts__phone" href="tel:+996550068215">
              + 996 (550) 068-215
            </a>
            <span className="header-contacts__btn">перезвонить вам?</span>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  )
}

export default Home