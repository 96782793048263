import React, { useState, useContext, useEffect } from 'react';
import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../../firebase';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  async function signup(email, password, firstName, lastName) {
    return (
      await createUserWithEmailAndPassword(auth, email, password)
      .then(
        cred => 
          setDoc(doc(db, "user", cred.user.uid)), {
            email,
            firstName,
            lastName
          }
      )
    );
  };

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  };
  
  function logout() {
    return signOut(auth);
  };

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  };

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  };

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
      setLoading(false);  
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup, 
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
