import catalog1 from '../assets/catalog1.jpg'
import catalog2 from '../assets/catalog2.jpg'
import catalog3 from '../assets/catalog3.jpg'
import catalog4 from '../assets/catalog4.jpg'
import catalog5 from '../assets/catalog5.jpg'
import catalog6 from '../assets/catalog6.jpg'
import catalog7 from '../assets/catalog7.jpg'
import catalog8 from '../assets/catalog8.jpg'
import catalog9 from '../assets/catalog9.jpg'
import catalog10 from '../assets/catalog10.avif'

export const catalogConfig = [
  {
    img: catalog1,
    title: 'Нефть и Газ',
    text: 'Химия для добычи нефти и газа',
    link: '/catalog/oil'
  },
  {
    img: catalog2,
    title: 'Энергетика',
    text: <>Химия для Энергетической <br/> Промышленности</>,
    link: '/catalog/energy'
  },
  {
    img: catalog3,
    title: 'Строительство',
    text: 'Химия для Строительства',
    link: '/catalog/build'
  },
  {
    img: catalog4,
    title: 'Удобрение',
    text: 'Удобрения для Сельского Хозяйства',
    link: '/catalog/fertile'
  },
  {
    img: catalog5,
    title: <>Дорожное <br/> Строительство</>,
    text: 'Химия для Дорожного Строительства',
    link: '/catalog/road'
  },
  {
    img: catalog6,
    title: 'Пищевое',
    text: 'Продукция для Пищевого Производства',
    link: '/catalog/food'
  },
  {
    img: catalog7,
    title: 'Дезинфекция',
    text: 'Химия для Очистки Воды и Дезинфекции',
    link: '/catalog/desinfect'
  },
  {
    img: catalog8,
    title: 'Добыча Урана',
    text: 'Химия для добычи и переработки Урана',
    link: '/catalog/uran'
  },
  {
    img: catalog9,
    title: 'Металлы',
    text: <>Химия для добычи и переработки <br/> металлов, руд, полезных ископаемых</>,
    link: '/catalog/metal'
  },
  {
    img: catalog10,
    title: <>Лабороторная <br/> посуда</>,
    text: 'Химические лабороторные посуды',
    link: '/catalog/dish'
  },
]