import React, { useState } from 'react'
import axios from 'axios'
import ym from 'react-yandex-metrika'
import { isMobile } from '../../lib/util'
import './index.css'

import ava from '../../assets/ava.png'

const Form = () => {
  const [form, setForm] = useState({
    name: '',
    phone: ''
  })
  const handleChange = (event) => {
    const { name, value } = event.target
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }))
  }
  const handleSave = async () => {
    ym('reachGoal', 'form1')
    if (!form.phone || form.phone.length < 9) {
      alert('Напишите ваш номер телефона')
      return
    }
    await axios.post(
      `https://api.telegram.org/bot5697398007:AAG8yiqLAy26STZHY_X_ZhgstWy16D5joqI/sendMessage?chat_id=-1001823618084&text=` +
      Object.values(form).map(item => `%0A${item}`).join(' ') + '&parse_mode=html'
    )
    setForm({
      name: '',
      phone: ''
    })
    alert('Заявка принята в обработку!')
  }
  return (
    <div className="form d-flex justify-between">
      <div>
        <h2 className="form__title">Есть вопросы ?</h2>
        <p className="form__text">Оставьте заявку на консультацию с менеджером</p>
        <div className="d-flex direction-column" style={{ marginTop: isMobile() ? 16 : 42 }}>
          <input name="name" placeholder="Напишите ваше имя" value={form.name} className="form__input" onChange={handleChange}/>
          <input name="phone" type="tel" placeholder="Напишите ваш номер телефона" value={form.phone} className="form__input" onChange={handleChange}/>
          <button className="form__btn" onClick={handleSave}>Записаться</button>
        </div>
      </div>
      <div className="d-flex direction-column" style={{ justifyContent: isMobile() ? 'flex-end' : 'flex-start' }}>
        <img alt="" src={ava} className="form__ava"/>
        <span className="form__author">Амина</span>
        <span className="form__desc">руководитель отдела заботы</span>
      </div>
    </div>
  )
}

export default Form