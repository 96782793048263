import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { collection, query, getDocs } from 'firebase/firestore'
import Autocomplete from 'react-autocomplete'
import { db } from '../../firebase'
import { catalogConfig } from '../../lib/env'
import { isMobile } from '../../lib/util'
import Form from '../../components/Form'
import './index.css'

import search from '../../assets/search.png'

const Catalog = () => {
  const history = useNavigate()
  const [list, setList] = useState([])
  const [filtered, setFiltered] = useState([])
  const [value, setValue] = useState('')

  const getItems = async (key) => {
    const q = query(collection(db, "catalog"))
    const querySnapshot = await getDocs(q)
    const array = []
    querySnapshot.forEach((doc) => {
      array.push({ id: doc.id, ...doc.data(), label: doc.data().title })
    })
    setList(array)
  }

  useEffect(() => {
    getItems()
  }, [])

  useEffect(() => {
    setFiltered(
      list.filter(el => el.label.toLowerCase().includes(value.toLowerCase()))
    )
  }, [value])

  return (
    <div className="catalog container">
      <h1 className="catalog__title">Продажа химической продукции</h1>
      <div className="catalog-search d-flex justify-between align-center">
        <Autocomplete
          getItemValue={(item) => item.label}
          items={filtered}
          renderItem={(item, isHighlighted) =>
            <div 
              style={{ 
                zIndex: 100,
                background: isHighlighted ? 'lightgray' : 'white',
                padding: '12px' 
              }}
            >
              {item.label}
            </div>
          }
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onSelect={(_, obj) => history(`/catalog/${obj.key}/${obj.id}`)}
          menuStyle={{
            position: 'absolute',
            zIndex: 100,
            border: 'none',
            outline: 'none',
          }}
        />
        <div>
          <img alt="" src={search} className="catalog-search__logo"/>
        </div>
      </div>
      <div className="catalog-container d-flex wrap justify-between">
        {catalogConfig.map((item, key) => 
          <Link to={item.link} key={key} className="catalog-container-item">
            <img alt="" src={item.img} className="catalog__img" />
            <div className="catalog-container-desc d-flex direction-column">
              <span className="catalog-container-item__title">{item.title}</span>
              <sub className="catalog-container-item__text">{item.text}</sub>
            </div>
          </Link>
        )}
      </div>
      <div style={{ marginTop: isMobile() ? 60 : 108 }}>
        <Form />
      </div>
    </div>
  )
}

export default Catalog