import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { collection, query, where, getDocs } from "firebase/firestore"
import { db } from '../../firebase'
import ReactPaginate from 'react-paginate'

const Items = () => {
  const location = useLocation()
  const history = useNavigate()
  const itemsPerPage = 12
  const [mainKey, setMainKey] = useState('')
  const [list, setList] = useState([])
  const [currentItems, setCurrentItems] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  const getItems = async (key) => {
    const q = query(collection(db, "catalog"), where("key", "==", key))
    const querySnapshot = await getDocs(q)
    const array = []
    querySnapshot.forEach((doc) => {
      array.push({ id: doc.id, ...doc.data() })
    })
    setList(array)
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length
    setItemOffset(newOffset)
  }

  useEffect(() => {
    const path = location.pathname
    const key = path.replace('/catalog/', '')
    setMainKey(key)
    getItems(key)
  }, [location])

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(list.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(list.length / itemsPerPage))
  }, [list, itemOffset, itemsPerPage])
  return (
    <>
      <div className="catalog-container d-flex wrap">
        {currentItems.map(item =>
          <div 
            key={item.id} 
            className="catalog-container__item d-flex direction-column justify-end"
            style={{  
              backgroundImage: `url(${item.img})`,
            }}
          >
            <span className="catalog-container__title">{item.title}</span>
            <span className="catalog-container__text">{item.body}</span>
            <button className="catalog-container__btn" onClick={() => history(`/catalog/${mainKey}/${item.id}`)}>подробнее...</button>
          </div>
        )}
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="вперед"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="назад"
        renderOnZeroPageCount={null}
        className="pagination"
      />
    </>
  )
}

export default Items