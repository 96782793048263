import React, { useState, useRef } from 'react'
import { useAuth } from '../contexts/authContext'
import { useNavigate } from 'react-router-dom'

import { Container, Card, Button, Form, Alert } from 'react-bootstrap'
import './index.css'

export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            history("/admin")
        } catch(e) {
            setError('Ошибка при входе в систему! Проверьте свои данные и попробуйте еще раз или обратитесь в службу поддержки!')
        }
        setLoading(false)
    }

    return (
        <Container 
            className="d-flex flex-column align-center justify-center mw-100" 
            style={{ minHeight: "100vh" }}
        >
            <div className="w-100" style={{ maxWidth: "400px" }}>
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4 login__title">Вход в систему</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit} className="login__form">
                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" ref={emailRef} required/>
                            </Form.Group>
                            <Form.Group id="password">
                                <Form.Label>Пароль</Form.Label>
                                <Form.Control type="password" ref={passwordRef} required/>
                            </Form.Group>
                            <Button disabled={loading} className="w-100 mt-3" type="submit">Войти</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    )
}