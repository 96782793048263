import React from 'react'
import ym from 'react-yandex-metrika'
import { isMobile } from '../../lib/util'
import './index.css'

import delivery1 from '../../assets/delivery1.png'
import delivery2 from '../../assets/delivery2.png'
import delivery3 from '../../assets/delivery3.png'
import delivery4 from '../../assets/delivery4.png'

import box from '../../assets/box.png'
import paper from '../../assets/paper.png'
import laptop from '../../assets/laptop.png'

const Delivery = () => {
  return (
    <div className="delivery container">
      <h1 className="delivery__title">Доставим за <span style={{ fontWeight: 700 }}>5 дней</span></h1>
      <p className="delivery__text">потому что мы работаем напрямую со складами</p>
      <div className="delivery-container d-flex justify-between wrap">
        <div className="delivery-container-item" style={{ top: 100 }}>
          <img alt="" src={delivery1} className="delivery__img"/>
          <div className="delivery-container-desc d-flex direction-column">
            <span className="delivery-container-item__title">ЖД <br/> перевозки</span>
            <a target="_blank" onClick={() => ym('reachGoal','whatsapp')} href="https://api.whatsapp.com/send?phone=996507068215&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D0%BF%D0%B8%D1%88%D1%83%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0" className="delivery-container__btn d-flex justify-center align-center">Рассчитать</a>
          </div>
        </div>
        <div className="delivery-container-item">
          <img alt="" src={delivery2} className="delivery__img"/>
          <div className="delivery-container-desc d-flex direction-column">
            <span className="delivery-container-item__title">Авиа <br/> перевозки</span>
            <a target="_blank" onClick={() => ym('reachGoal','whatsapp')} href="https://api.whatsapp.com/send?phone=996507068215&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D0%BF%D0%B8%D1%88%D1%83%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0" className="delivery-container__btn d-flex justify-center align-center">Рассчитать</a>
          </div>
        </div>
        <div className="delivery-container-item" style={{ top: 140 }}>
          <img alt="" src={delivery3} className="delivery__img"/>
          <div className="delivery-container-desc d-flex direction-column">
            <span className="delivery-container-item__title">Морские <br/> перевозки</span>
            <a target="_blank" onClick={() => ym('reachGoal','whatsapp')} href="https://api.whatsapp.com/send?phone=996507068215&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D0%BF%D0%B8%D1%88%D1%83%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0" className="delivery-container__btn d-flex justify-center align-center">Рассчитать</a>
          </div>
        </div>
        <div className="delivery-container-item" style={{ top: 80 }}>
          <img alt="" src={delivery4} className="delivery__img"/>
          <div className="delivery-container-desc d-flex direction-column">
            <span className="delivery-container-item__title">Авто <br/> доставка</span>
            <a target="_blank" onClick={() => ym('reachGoal','whatsapp')} href="https://api.whatsapp.com/send?phone=996507068215&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D0%BF%D0%B8%D1%88%D1%83%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0" className="delivery-container__btn d-flex justify-center align-center">Рассчитать</a>
          </div>
        </div>
      </div>
      <div className="delivery-guarantee">
        <h2 className="delivery-guarantee__title"><span style={{ fontWeight: 700 }}>Гарантируем</span> сохранность груза</h2>
        <div className="d-flex justify-between" style={{ marginTop: isMobile() ? 60 : 138 }}>
          <div className="d-flex direction-column">
            <span className="delivery-guarantee__text">Заявка с сайта</span>
            <svg width="8" height="97" viewBox="0 0 8 97" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: isMobile() ? 16 : 60, marginLeft: isMobile() ? 42 : 136 }}>
              <path d="M3.64645 96.3536C3.84171 96.5488 4.15829 96.5488 4.35355 96.3536L7.53553 93.1716C7.7308 92.9763 7.7308 92.6597 7.53553 92.4645C7.34027 92.2692 7.02369 92.2692 6.82843 92.4645L4 95.2929L1.17157 92.4645C0.976311 92.2692 0.659728 92.2692 0.464466 92.4645C0.269204 92.6597 0.269204 92.9763 0.464466 93.1716L3.64645 96.3536ZM3.5 1.65031e-10L3.5 96L4.5 96L4.5 -1.65031e-10L3.5 1.65031e-10Z" fill="black"/>
            </svg>
            <span className="delivery-guarantee__text" style={{ marginTop: 60 }}>Расчет логистики</span>
            <svg width="105" height={isMobile() ? "100" : "128"} viewBox="0 0 105 134" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: isMobile() ? 16 : 60, marginLeft: isMobile() ? 42 : 136 }}>
              <path d="M103.864 130.593C104.063 130.403 104.07 130.086 103.879 129.887L100.768 126.635C100.577 126.436 100.261 126.429 100.061 126.619C99.8619 126.81 99.8549 127.127 100.046 127.326L102.811 130.217L99.921 132.982C99.7214 133.173 99.7145 133.489 99.9054 133.689C100.096 133.888 100.413 133.895 100.612 133.704L103.864 130.593ZM5.31092 0.0861235C-2.85269 30.4054 0.0361338 62.5842 15.8201 87.4387C31.6165 112.313 60.2818 129.778 103.507 130.732L103.529 129.732C60.5879 128.784 32.256 111.454 16.6643 86.9027C1.06019 62.3314 -1.82993 30.4532 6.27653 0.346118L5.31092 0.0861235Z" fill="black"/>
            </svg>
          </div>
          <div className="d-flex direction-column">
            <img alt="" src={box} style={{ width: isMobile() ? '130px' : '400px' }}/>
            <span className="delivery-guarantee__text" style={{ marginTop: isMobile() ? 172 : 80, textAlign: 'center' }}>Заключение договора</span>
          </div>
          <div className="d-flex direction-column">
            <span className="delivery-guarantee__text" style={{ textAlign: 'right' }}>Доставка</span>
            <svg width="8" height="97" viewBox="0 0 8 97" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: isMobile() ? 16 : 60, marginLeft: isMobile() ? 54 : 86 }}>
              <path d="M4.58353 0.630699C4.38827 0.43544 4.07168 0.43544 3.87642 0.630699L0.694442 3.81268C0.49918 4.00794 0.49918 4.32452 0.694443 4.51979C0.889705 4.71505 1.20629 4.71505 1.40155 4.51979L4.22998 1.69136L7.0584 4.51979C7.25367 4.71505 7.57025 4.71505 7.76551 4.51979C7.96077 4.32452 7.96077 4.00794 7.76551 3.81268L4.58353 0.630699ZM4.72998 96.9843L4.72998 0.984253L3.72998 0.984253L3.72998 96.9843L4.72998 96.9843Z" fill="black"/>
            </svg>
            <span className="delivery-guarantee__text" style={{ marginTop: 60, textAlign: 'right' }}>Оплата</span>
            <svg width="92" height={isMobile() ? "100" : "128"} viewBox="0 0 92 128" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: isMobile() ? 16 : 60, marginLeft: isMobile() ? 0 : 24 }}>
              <path d="M84.7724 0.553936C84.5261 0.429122 84.2252 0.52764 84.1004 0.773981L82.0666 4.78817C81.9418 5.0345 82.0404 5.33536 82.2867 5.46017C82.533 5.58497 82.8339 5.48645 82.9587 5.24012L84.7665 1.67195L88.3347 3.47976C88.581 3.60456 88.8819 3.50604 89.0067 3.25971C89.1315 3.01338 89.033 2.71251 88.7866 2.58771L84.7724 0.553936ZM0.619383 127.52C29.7774 125.314 57.1534 112.824 74.1076 91.2688C91.0761 69.6953 97.5514 39.1091 85.0216 0.844361L84.0713 1.15554C96.5126 39.1503 90.0541 69.3772 73.3216 90.6506C56.5749 111.942 29.4828 124.333 0.543953 126.522L0.619383 127.52Z" fill="black"/>
            </svg>
          </div>
        </div>
      </div>
      <div className="delivery-form d-flex direction-column align-center">
        <h2 className="delivery-form__title"><span style={{ fontWeight: 700 }}>Ответьте на 4 вопроса</span> и получите рассчёт стоимости доставки до вашего города</h2>
        <span className="delivery-form__text">Вы получите</span>
        <div className="d-flex" style={{ marginTop: 30 }}>
          <div className="d-flex align-center" style={{ marginRight: 60 }}>
            <img alt="" src={paper} className="delivery-form-item__img"/>
            <span className="delivery-form-item__text">Подробный рассчёт <br /> стоимости доставки</span>
          </div>
          <div className="d-flex align-center">
            <img alt="" src={laptop} className="delivery-form-item__img"/>
            <span className="delivery-form-item__text">Консультацию от <br /> нашего логиста</span>
          </div>
        </div>
        <a target="_blank" onClick={() => ym('reachGoal','whatsapp')} href="https://api.whatsapp.com/send?phone=996507068215&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D0%BF%D0%B8%D1%88%D1%83%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0" className="delivery-form__btn">Рассчитать</a>
      </div>
    </div>
  )
}

export default Delivery