import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectCoverflow } from "swiper"
import InputMask from 'react-input-mask'
import ym from 'react-yandex-metrika'
import { isMobile } from '../../lib/util'
import { catalogConfig } from '../../lib/env'
import 'swiper/css/effect-coverflow'
import 'swiper/css'
import './index.css'

import icon1 from '../../assets/icon1.png'
import icon2 from '../../assets/icon2.png'
import icon3 from '../../assets/icon3.png'
import icon4 from '../../assets/icon4.png'

import graph from '../../assets/graph.png'
import lock from '../../assets/lock.png'

import sel1 from '../../assets/sel1.png'
import sel2 from '../../assets/sel2.png'
import sel3 from '../../assets/sel3.png'
import sel4 from '../../assets/sel4.png'

import client1 from '../../assets/client1.png'
import client2 from '../../assets/client2.png'
import client3 from '../../assets/client3.png'
import client4 from '../../assets/client4.png'
import client5 from '../../assets/client5.png'

import director from '../../assets/director.png'

const Main = () => {
  const history = useNavigate()
  const [form, setForm] = useState({
    name: '',
    phone: ''
  })
  const handleChange = (event) => {
    const { name, value } = event.target
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }))
  }
  const handleSave = async () => {
    ym('reachGoal', 'form1')
    if (!form.phone || form.phone.length < 9) {
      alert('Напишите ваш номер телефона')
      return
    }
    await axios.post(
      `https://api.telegram.org/bot5697398007:AAG8yiqLAy26STZHY_X_ZhgstWy16D5joqI/sendMessage?chat_id=-1001823618084&text=` +
      Object.values(form).map(item => `%0A${item}`).join(' ') + '&parse_mode=html'
    )
    setForm({
      name: '',
      phone: ''
    })
    alert('Заявка принята в обработку!')
  }
  return (
    <>
      <div className="main container">
        <h1 className="main__title">
          <span className="lighted">Промышленная химия</span> <br/>
          оптовые поставки химического сырья
        </h1>
        <div className="d-flex justify-between align-end">
          <p className="main__text">Также <span className="lighted">лабораторная техника</span></p>
          <div className="main-doc">
            <span className="main-doc__text">
              Каждый товар <br/>
              соответствует ГОСТ <br/>
              и имеет гарантийные талоны
            </span>
          </div>
        </div>
        <div className="d-flex justify-between align-end">
          {!isMobile() &&
            <div className="main-container d-flex wrap justify-between">
              <div className="main-item">
                <img alt="" src={icon1} className="main-item__img"/>
                <span className="main-item__text">Химия для строительства</span>
              </div>
              <div className="main-item">
                <img alt="" src={icon2} className="main-item__img"/>
                <span className="main-item__text">Химия для удобрения</span>
              </div>
              <div className="main-item">
                <img alt="" src={icon3} className="main-item__img"/>
                <span className="main-item__text">Продукция для Пищевого Производства</span>
              </div>
              <div className="main-item">
                <img alt="" src={icon4} className="main-item__img"/>
                <span className="main-item__text">Лабораторная посуда</span>
              </div>
            </div>
          }
          <div style={{ marginTop: isMobile() ? 30 : 0 }}>
            <a target="_blank" onClick={() => ym('reachGoal','whatsapp')} href="https://api.whatsapp.com/send?phone=996507068215&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D0%BF%D0%B8%D1%88%D1%83%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0" className="main__btn">
              Рассчитать стоимость
            </a>
          </div>
        </div>
        <div className="main-carousel">
          <h2 className="main-carousel__title">Более 35О продукций</h2>
          <p className="main-carousel__text">для разных предприятий в нашем каталоге</p>
          <Swiper
            effect={"coverflow"}
            initialSlide={2}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            modules={[EffectCoverflow]}
            className="mySwiper"
          >
            {catalogConfig.map((item, i) => 
              <SwiperSlide key={i}>
                <img alt="" src={item.img} className="main-carousel__img"/>
                <span className="main-carousel__label">{item.title}</span>
                <button className="main-carousel__btn" onClick={() => history(item.link)}>подробнее</button>
              </SwiperSlide>
            )}
          </Swiper>
          <div className="main-keys d-flex justify-center">
            <div className="d-flex" style={{ marginRight: 60 }}>
              <img alt="" src={graph} className="main-keys-item__img"/>
              <span className="main-keys-item__text">
                Цены на 15% ниже <br/>
                рыночных 
              </span>
            </div>
            <div className="d-flex">
              <img alt="" src={lock} className="main-keys-item__img"/>
              <span className="main-keys-item__text">
                135 химий в день 
              </span>
            </div>
          </div>
        </div>
        <div className="main-form">
          <h2 className="main-form__title">
            Получите полный <br />
            <span className="lighted">каталог продукций</span>{isMobile() && <br/>} с ценами
          </h2>
          <div className="d-flex" style={{ marginBottom: isMobile() ? 16 : 72}}>
            <input placeholder="Имя" name="name" type="text" value={form.name} className="main-form__input" onChange={handleChange} style={{ marginRight: isMobile() ? 8 : 16 }}/>
            <input name="phone" placeholder="Номер телефона" type="tel" value={form.phone} className="main-form__input" onChange={handleChange}/>
          </div>
          <button className="main-form__btn" onClick={handleSave}>получить</button>
        </div>
        <div className="main-guarantee">
          <h2 className="main-guarantee__title">
            Гарантия качества на все товары <br/>
            со дня <span>1</span> покупки
            <sub>год</sub>
          </h2>
          <p className="main-guarantee__text">
            Если в течении года оборудование выйдет из строя, мы полностью его заменим за свой счет
          </p>
        </div>
        <div className="main-selection">
          <h2 className="main-selection__title">Почему выбирают нас ?</h2>
          <div className="d-flex justify-between">
            <div className="main-selection-item">
              <img alt="" src={sel1} className="main-selection-item__img"/>
              <span className="main-selection-item__text">Награды</span>
            </div>
            <div className="main-selection-item">
              <img alt="" src={sel2} className="main-selection-item__img"/>
              <span className="main-selection-item__text">Лицензий</span>
            </div>
            <div className="main-selection-item">
              <img alt="" src={sel3} className="main-selection-item__img"/>
              <span className="main-selection-item__text">Сертификаты</span>
            </div>
            <div className="main-selection-item">
              <img alt="" src={sel4} className="main-selection-item__img"/>
              <span className="main-selection-item__text">Доставка</span>
            </div>
          </div>
        </div>
        <div className="main-video container d-flex direction-column align-center">
          <h2 className="main-video__title">300 квадратных метров</h2>
          <p className="main-video__text">познакомьтесь со складом, который будет производить{isMobile() && <br/>} вашу продукцию</p>
          <iframe width={isMobile() ? "350px" : "100%"} height={isMobile() ? "198px" : "600px"} src="https://www.youtube.com/embed/le10A6AtwhM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
          </iframe>
        </div>
        <div className="main-clients container d-flex align-center">
          <div className="main-clients-desc">
            <p className="main-clients-desc__text">а так же, среди <br/> наших клиентов</p>
          </div>
          <div className="d-flex justify-between align-center" style={{ width: '100%' }}>
            <img alt="" src={client1} style={isMobile() ? { width: 35, height: 35 } : { width: 102, height: 102 }} />
            <img alt="" src={client2} style={isMobile() ? { width: 34, height: 25 } : { width: 100, height: 73 }} />
            <img alt="" src={client3} style={isMobile() ? { width: 56, height: 22 } : { width: 168, height: 63 }} />
            <img alt="" src={client4} style={isMobile() ? { width: 54, height: 25 } : { width: 158, height: 72 }} />
            <img alt="" src={client5} style={isMobile() ? { width: 39, height: 23 } : { width: 113, height: 68 }} />
          </div>
        </div>
        <div className="main-cta container d-flex justify-between">
          <div className="d-flex direction-column">
            <h2 className="main-cta__title">Остались вопросы?</h2>
            <img alt="" src={director} className="main-cta__img"/>
            <div className="main-cta-desc d-flex direction-column">
              <span className="main-cta-desc__title">Рустам</span>
              <span className="main-cta-desc__text">Управляющий директор</span>
            </div>
          </div>
          <div className="main-cta-form">
            <h3 className="main-cta-form__title">Заполните форму</h3>
            <p className="main-cta-form__text">и запишитесь на консультацию с управляющим директором</p>
            <div className="d-flex direction-column" style={{ marginBottom: isMobile() ? 12 : 25 }}>
              <span className="main-cta-form__label">Ваше имя</span>
              <input name="name" type="text" value={form.name} className="main-cta-form__input" onChange={handleChange}/>
            </div>
            <div className="d-flex direction-column" style={{ marginBottom: isMobile() ? 12 : 25 }}>
              <span className="main-cta-form__label">Ваш номер телефона</span>
              <input name="phone" type="tel" value={form.phone} className="main-cta-form__input" onChange={handleChange}/>
            </div>
            <button className="main-cta-form__btn" onClick={handleSave}>Записаться</button>
          </div>
        </div>
        <div id="contacts" className="container" style={{ marginTop: isMobile() ? 42 : 108, position: 'relative' }}>
          <div className="main-map">
            <span className="main-map__text">
              Наши контакты: <br/>
              <br/>
              <span style={{ fontWeight: 700 }}>+ 996 (550) 068-215</span><br/>
              <br/>
              Офис: г. Бишкек<br/>
              ул. Токомбаева, 21/3а
            </span>
          </div>
        </div>
      </div>
      <iframe frameBorder="no" style={{ border: '1px solid #a3a3a3', boxSizing: 'border-box' }} width="100%" height={isMobile() ? '320px' : '850px'} src="https://widgets.2gis.com/widget?type=firmsonmap&amp;options=%7B%22pos%22%3A%7B%22lat%22%3A42.8187%2C%22lon%22%3A74.617019%2C%22zoom%22%3A16%7D%2C%22opt%22%3A%7B%22city%22%3A%22bishkek%22%7D%2C%22org%22%3A%2270000001034037448%22%7D"></iframe>
    </>
  )
}

export default Main