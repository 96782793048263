import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'
import Autocomplete from 'react-autocomplete'
import { collection, query, where, getDocs } from "firebase/firestore"
import { db } from '../../firebase'
import { catalogConfig } from '../../lib/env'
import { isMobile } from '../../lib/util'
import Form from '../../components/Form'
import './index.css'

import search from '../../assets/search.png'

const CatalogItem = () => {
  const history = useNavigate()
  const location = useLocation()
  const [title, setTitles] = useState('')
  const [list, setList] = useState([])
  const [filtered, setFiltered] = useState([])
  const [value, setValue] = useState('')

  const getItems = async (key) => {
    const q = query(collection(db, "catalog"), where("key", "==", key))
    const querySnapshot = await getDocs(q)
    const array = []
    querySnapshot.forEach((doc) => {
      array.push({ id: doc.id, ...doc.data(), label: doc.data().title })
    })
    setList(array)
  }

  useEffect(() => {
    let path = location.pathname
    const temp = location.pathname.split('/')
    if (temp.length === 4) {
      path = path.replace(`/${temp[3]}`, '')
    }
    const selected = catalogConfig.find(f => f.link === path)
    setTitles(selected.text)
    getItems(temp[2])
  }, [location])

  useEffect(() => {
    setFiltered(
      list.filter(el => el.label.toLowerCase().includes(value.toLowerCase()))
    )
  }, [value])
  return (
    <div className="catalog container">
      <h1 className="catalog__title">{title}</h1>
      <div className="catalog-search d-flex justify-between align-center">
        <Autocomplete
          getItemValue={(item) => item.label}
          items={filtered}
          renderItem={(item, isHighlighted) =>
            <div 
              style={{ 
                zIndex: 100,
                background: isHighlighted ? 'lightgray' : 'white',
                padding: '12px' 
              }}
            >
              {item.label}
            </div>
          }
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onSelect={(_, obj) => history(`/catalog/${obj.key}/${obj.id}`)}
          menuStyle={{
            position: 'absolute',
            zIndex: 100,
            border: 'none',
            outline: 'none',
          }}
        />
        <div>
          <img alt="" src={search} className="catalog-search__logo"/>
        </div>
      </div>
      <Outlet />
      <div style={{ marginTop: isMobile() ? 60 : 108 }}>
        <Form />
      </div>
    </div>
  )
}

export default CatalogItem