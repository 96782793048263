import React from 'react'
import {
  Routes,
  Route,
  Navigate  
} from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'
import { AuthProvider } from './components/auth/contexts/authContext';
import Login from './components/auth/login/login'
import PrivateRoute from './components/PrivateRoute'
import Home from './pages/Home'
import Main from './pages/Main'
import Catalog from './pages/Catalog'
import Delivery from './pages/Delivery'
import Contacts from './pages/Contacts'
import CatalogItem from './pages/CatalogItem'
import Items from './pages/Items'
import ItemDetailed from './pages/ItemDetailed'
import Admin from './pages/Admin'

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Routes>
          <Route exact path='/' element={<IndexRedirect />}/>
          <Route path='/login' element={<Login />}/>
          <Route 
            path='/admin' 
            element={
              <PrivateRoute>
                <Admin/>
              </PrivateRoute>
            }
          />
          <Route path='/' element={<Home/>}>
            <Route path='main' element={<Main />} />
            <Route path='catalog' element={<Catalog />} />
            <Route path='catalog/:slug/' element={<CatalogItem />}>
              <Route exact path='' element={<Items />}/>
              <Route path=':id' element={<ItemDetailed />}/>
            </Route>
            <Route path='delivery' element={<Delivery />} />
            <Route path='contacts' element={<Contacts />} />
          </Route>
        </Routes>
      </AuthProvider>
      <YMInitializer accounts={[92563799]} />
    </div>
  )
}

const IndexRedirect = () => {
  return <Navigate to="/main" />
}

export default App
