import React, { useState, useEffect } from 'react'
import ym from 'react-yandex-metrika'
import { doc, getDoc } from "firebase/firestore"
import { useLocation } from 'react-router-dom'
import { db } from '../../firebase'
import { isMobile } from '../../lib/util'

const ItemDetailed = () => {
  const location = useLocation()
  const [data, setData] = useState({})

  const getData = async (id) => {
    const docRef = doc(db, "catalog", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setData(docSnap.data())
    } else {
      console.log("No such document!");
    }
  }

  useEffect(() => {
    const path = location.pathname
    const array = path.split('/')
    getData(array[3])
  }, [location])
  return (
    <div className="d-flex" style={{ marginTop: isMobile() ? 42 : 108, flexDirection: isMobile() ? 'column' : 'row' }}>
      <div style={{ overflow: 'hidden', minWidth: isMobile() ? '100%' : 408 }}>
        <img alt="" src={data.img} style={{ width: '100%' }}/>
      </div>
      <div style={{ marginTop: isMobile() ? 24 : 0, marginLeft: isMobile() ? 0 : 90 }}>
        <h2 
          style={{
            fontWeight: 600,
            fontSize: isMobile() ? 30 : 50,
          }}
        >
          {data.title}
        </h2>
        <p
          style={{
            marginTop: isMobile() ? 21 : 42,
            fontWeight: 400,
            fontSize: isMobile() ? 16 : 23,
            lineHeight: isMobile() ? '20px' : '29px',
            whiteSpace: 'pre-line'
          }}
        >
          {data.body}
        </p>
        <a target="_blank" onClick={() => ym('reachGoal','whatsapp')} href="https://api.whatsapp.com/send?phone=996507068215&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D0%BF%D0%B8%D1%88%D1%83%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0" className="btn-primary d-flex justify-center align-center" style={{ marginTop: 42, width: 230 }}>Написать нам</a>
      </div>
    </div>
  )
}

export default ItemDetailed