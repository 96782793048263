import React, { useRef, useState, useEffect } from 'react'
import { collection, query, where, getDocs, doc, addDoc, updateDoc } from "firebase/firestore"
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { catalogConfig } from '../../lib/env'
import { isMobile } from '../../lib/util'
import { db } from '../../firebase'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import Modal from 'react-modal'
import 'react-accessible-accordion/dist/fancy-example.css'
import './index.css'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const Admin = () => {
  const storage = getStorage()
  const hiddenFileInput = useRef(null)
  const [list, setList] = useState([])
  const [modalIsOpen, setIsOpen] = useState(false)
  const [form, setForm] = useState({
    body: '',
    img: '',
    key: 'oil',
    title: ''
  })

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setForm({
      body: '',
      img: '',
      key: 'oil',
      title: ''
    })
    setIsOpen(false)
  }

  const handleEdit = (data) => {
    setForm(data)
    setIsOpen(true)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setForm(prevState => ({
      ...prevState,
      [name]: value
    })) 
  }

  const handleUpload = (event) => {
    const { files } = event.target
    const storageRef = ref(storage, files[0].name)
    const uploadTask = uploadBytesResumable(storageRef, files[0])
    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            console.log('Upload is running')
            break
        }
      }, 
      (error) => {
        // Handle unsuccessful uploads
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setForm(prevState => ({
            ...prevState,
            img: downloadURL
          }))
        })
      }
    )
  }

  const handleSave = async () => {
    if (Object.values(form).some(value => value.length < 2)) {
      alert('Заполните все поля!')
      return
    }
    if (form.id) {
      await updateDoc(doc(db, "catalog", form.id), form)
    } else {
      await addDoc(collection(db, "catalog"), form)
    }
    closeModal()
    getItems()
  }

  const getItems = async () => {
    const array = await Promise.all(catalogConfig.map(item => getDocs(query(collection(db, "catalog"), where("key", "==", item.link.replace('/catalog/', ''))))))
    setList(array.map((querySnapshot, index) => {
      const temp = []
      querySnapshot.forEach((doc) => {
        temp.push({ id: doc.id, ...doc.data() })
      })
      return { ...catalogConfig[index], products: temp }
    }))
  }

  useEffect(() => {
    getItems()
  }, [])

  return (
    <div className="container">
      <div className="d-flex justify-between align-center">
        <h1 className="admin__title">Админка</h1>
        <button className="admin__btn" onClick={openModal}>Добавить</button>
      </div>
      <Accordion>
        {list.map(item =>
          <AccordionItem key={item.link}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {item.title}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {item.products.map(product =>
                  <div className="product d-flex" key={product.id} style={{ flexDirection: isMobile() ? 'column' : 'row' }}>
                    <div>
                      <img src={product.img} style={{ width: 120 }}/>
                    </div>
                    <div style={{ marginLeft: isMobile() ? 0 : 24 }}>
                      <h2 className="product__title">{product.title}</h2>
                      <p className="product__text">{product.body}</p>
                      <button className="admin__btn" onClick={() => handleEdit(product)} style={{ marginTop: 24, fontSize: 14 }}>Изменить</button>
                    </div>
                  </div>
                )}
              </AccordionItemPanel>
          </AccordionItem>
        )}
      </Accordion>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal d-flex" style={{ flexDirection: isMobile() ? 'column' : 'row' }}>
          <div 
            className="modal-img d-flex justify-center align-end"
            style={{
              background: `url(${form.img}) center center no-repeat`,
              backgroundSize: 'cover'
            }}
          >
            <button className="modal-img__btn" onClick={() => hiddenFileInput.current.click()}>выбрать фото</button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleUpload}
              style={{ display: 'none' }}
            />
          </div>
          <div style={{ marginTop: isMobile() ? 24 : 0 }}>
            <select name="key" className="modal__input" value={form.key} onChange={handleChange}>
              {catalogConfig.map(item =>
                <option key={item.link} value={item.link.replace('/catalog/', '')}>{item.title}</option>
              )}
            </select>
            <input name="title" type="text" value={form.title} placeholder="Заголовок" className="modal__input" onChange={handleChange}/>
            <textarea name="body" type="text" value={form.body} placeholder="Описание" className="modal__input" onChange={handleChange} style={{ height: isMobile() ? '200px' : 'calc(100% - 122px)' }}/>
          </div>
        </div>
        <div className="d-flex" style={{ flexDirection: isMobile() ? 'column' : 'row' }}>
          <button className="admin__btn" onClick={handleSave} style={{ marginTop: 24, width: '100%' }}>Отправить</button>
          <button className="admin__btn" onClick={closeModal} style={{ marginTop: 24, width: '100%', background: 'transparent', color: '#000' }}>Отмена</button>
        </div>
      </Modal>
    </div>
  )
}

export default Admin
